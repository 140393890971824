<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn
          elevation="0"
          color="#fff"
          class="
            font-weight-bolder
            btn-outline-default
            py-4
            px-4
            mx-4
            btn-bottom
          "
          small
          v-on="on"
        >
          AJOUTER UN VEHICULE TEMPORAIRE
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          AJOUTER UN VEHICULE TEMPORAIRE
        </v-card-title>
        <v-card-text v-if="loading">
          <div class="grid-center-h-v">
            <v-progress-circular
              :size="32"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <!-- CONFIRMATION MESSAGE -->
          <div v-if="message">
            <p :style="{ color: messageStatus }">{{ message }}</p>
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="closeDialog"
            >
              FERMER
            </v-btn>
          </div>
          <!-- FORM -->
          <div v-else>
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >TYPE DE VÉHICULE</label
            >
            <v-select
              v-model="vehicleType"
              :items="vehicleList"
              item-text="key"
              item-value="value"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
              "
              outlined
              single-line
              height="36"
            >
            </v-select>
            <!-- PLATE NUMBER -->
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >IMMATRICULATION</label
            >
            <v-text-field
              v-model="plateNumber"
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder=""
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                text-light-input
                mt-2
              "
            >
            </v-text-field>
            <!-- SAVE OR CANCEL -->
            <div class="d-flex justify-end mt-8">
              <v-btn
                elevation="0"
                color="#fff"
                class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
                small
                @click="closeDialog"
              >
                ANNULER
              </v-btn>
              <v-btn
                :elevation="0"
                color="#cb0c9f"
                class="
                  font-weight-bolder
                  btn-danger
                  bg-gradient-info
                  py-4
                  px-2
                  mt-auto
                "
                small
                :disabled="!canAddVehicle"
                @click="createTempVehicle"
              >
                Enregistrer
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MesVehiculesAddTemp",

  data() {
    return {
      dialog: false,
      loading: false,
      message: null,
      messageStatus: null,
      vehicleList: [
        {
          key: "Voiture",
          value: "cars",
        },
        {
          key: "Moto",
          value: "motorbike",
        },
      ],
      plateNumber: null,
      vehicleType: null,
    };
  },

  computed: {
    canAddVehicle() {
      return (
        this.plateNumber &&
        this.plateNumber.trim().length > 3 &&
        this.vehicleType
      );
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      this.loading = false;
      this.message = null;
      this.messageStatus = null;
      this.plateNumber = null;
      this.vehicleType = null;
    },

    createTempVehicle() {
      const body = {
        plateNumber: this.plateNumber.trim(),
        vehicleType: this.vehicleType,
      };
      this.loading = true;
      axios
        .post(`${this.$store.getters.apiurl}/plates/`, body)
        .then((res) => {
          if (res.data.message === "done") {
            this.message = "Enregistrement effectué";
            this.messageStatus = "green";
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.message = "L' enregistrement à échoué";
          this.messageStatus = "red";
          console.error("Errorn on post temp vehicle", err);
        });
    },
  },
};
</script>

<style scoped></style>
