<template>
  <div>
    <v-card class="card-shadow border-radius-xl px-4 py-4">
      <div class="resa-head">
        <h4>Mes réservations</h4>
        <CreateReservationUser :user="user"></CreateReservationUser>
      </div>
      <!-- Resa Table -->
      <GridReservation
        class="mt-8"
        :user="user"
        @updateReservations="updateReservations"
      ></GridReservation>
      <!-- PERIODIC RESERVATION TABLE -->
      <div v-if="canMakePeriodicReservations">
        <h4 class="mt-8">Mes réservations périodique</h4>
        <PeriodicReservationTable></PeriodicReservationTable>
      </div>
    </v-card>
  </div>
</template>

<script>
import GridReservation from "./GridReservation.vue";
import CreateReservationUser from "./CreateReservationUser.vue";
import PeriodicReservationTable from "./PeriodicReservationTable.vue";

export default {
  name: "MesReservations",

  components: {
    GridReservation,
    CreateReservationUser,
    PeriodicReservationTable,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canMakePeriodicReservations() {
      const userReservationPermissions = this.user.permissions.permissions.find(
        (permissions) => permissions.name === "reservation"
      );
      return userReservationPermissions?.restrictions?.periodicAllowed || false;
    },
  },

  methods: {
    updateReservations() {
      this.$emit("updateReservations");
    },
  },
};
</script>

<style>
.resa-head {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
}
</style>
