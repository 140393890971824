<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn
          :elevation="0"
          color="#fff"
          class="font-weight-bolder btn-outline-default py-4 px-8"
          small
          v-on="on"
        >
          RÉSERVER À L'AVANCE
        </v-btn>
      </template>
      <v-card class="card-shadow border-radius-xl px-4 py-4 w-100">
        <v-card-title class="text-h5"> Créer une reservation </v-card-title>
        <div class="tab" v-if="canMakePeriodicReservations">
          <button
            :class="formSelection == 'UNIQUE' ? 'selected-tab' : ''"
            @click="formSelection = 'UNIQUE'"
          >
            Unique
          </button>
          <button
            :class="formSelection == 'PERIODIC' ? 'selected-tab' : ''"
            @click="formSelection = 'PERIODIC'"
          >
            Périodique
          </button>
        </div>
        <UniqueReservation
          v-if="formSelection == 'UNIQUE'"
          :user="user"
          @cancel="dialog = false"
        ></UniqueReservation>
        <PeriodiqueReservationForm
          v-if="formSelection == 'PERIODIC'"
          :user="user"
          @cancel="dialog = false"
        ></PeriodiqueReservationForm>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UniqueReservation from "./UniqueReservation.vue";
import PeriodiqueReservationForm from "./periodiqueReservationForm.vue";

export default {
  name: "CreateReservationUser",

  components: {
    UniqueReservation,
    PeriodiqueReservationForm,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      formSelection: "UNIQUE",
    };
  },

  computed: {
    canMakePeriodicReservations() {
      const userReservationPermissions = this.user.permissions.permissions.find(
        (permissions) => permissions.name === "reservation"
      );
      return userReservationPermissions?.restrictions?.periodicAllowed ?? false;
    },
  },
};
</script>

<style scoped>
.tab {
  overflow: hidden;
  margin: 0 12px 2rem 12px;
}

.tab button {
  background-color: inherit;
  float: left;
  outline: none;
  cursor: pointer;
  padding: 12px 1rem;
  font-size: 1rem;
  color: #00000099;
}

.selected-tab {
  border-bottom: 3px solid #1c71bc;
}

.tab button:hover {
  background-color: rgb(248, 248, 248);
}

.custom-date-picker {
  height: 36px;
  margin-left: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

.period-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  gap: 2rem;
  margin-left: 1rem;
}

.bottom-action-btn {
  display: flex;
  justify-content: space-between;
  padding-inline: 1rem;
}

@media screen and (max-width: 640px) {
  .period-selector {
    gap: 1rem;
  }
}
</style>
