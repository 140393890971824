<template>
  <div class="h-100">
    <v-card class="card-shadow border-radius-xl h-100">
      <div class="d-flex justify-space-between px-4 pt-4">
        <h6 class="mb-0 text-h6 text-typo font-weight-bold">Mes véhicules</h6>
      </div>
      <div class="px-4 py-4">
        <v-list>
          <v-list-item-group class="border-radius-sm">
            <v-list-item
              :ripple="false"
              v-for="vehicle in vehicleList"
              :key="vehicle.immatriculation"
              class="px-0 border-radius-sm mb-2"
            >
              <v-avatar
                width="48"
                height="48"
                class="shadow border-radius-lg me-4"
              >
                <img
                  :src="vehicle.avatar"
                  alt="Avatar"
                  class="border-radius-lg px-1 py-1"
                />
              </v-avatar>
              <v-list-item-content>
                <div class="d-flex align-center">
                  <div>
                    <h6 class="mb-0 text-sm text-sm text-typo font-weight-bold">
                      {{ vehicle.immatriculation }}
                    </h6>
                    <p class="mb-0 text-xs text-body">
                      {{ vehicle.status }}
                    </p>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-if="canAddTempVehicle" class="d-flex justify-center">
          <MesVehiculesAddTemp class="btn-bottom"></MesVehiculesAddTemp>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import MesVehiculesAddTemp from "./MesVehiculesAddTemp.vue";

export default {
  name: "MesVehicules",

  components: {
    MesVehiculesAddTemp,
  },

  props: {
    vehicles: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    vehicleList() {
      let vehicleList = [];
      if (this.vehicles.cars && this.vehicles.cars.length > 0) {
        this.vehicles.cars.forEach((immat) => {
          const car = {
            avatar: require("@/assets/icons/car.svg"),
            immatriculation: immat,
            status: "Plaque enregistrée",
          };
          vehicleList.push(car);
        });
      }
      if (this.vehicles.motorbike && this.vehicles.motorbike.length > 0) {
        this.vehicles.motorbike.forEach((immat) => {
          const motorbike = {
            avatar: require("@/assets/icons/moto.svg"),
            immatriculation: immat,
            status: "Plaque enregistrée",
          };
          vehicleList.push(motorbike);
        });
      }

      return vehicleList;
    },

    canAddTempVehicle() {
      // if (this.user === null) return false;
      return (
        this.user &&
        this.user.permissions &&
        this.user.permissions.permissions &&
        this.user.permissions.permissions.find(
          (elm) => elm.name === "temporaryPlate"
        ) !== undefined
      );
    },
  },
};
</script>

<style scoped>
.btn-bottom {
  position: absolute;
  bottom: 1rem;
}
</style>
