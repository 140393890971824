<template>
  <v-card-text>
    <div>
      <div v-if="pendingRequest" class="grid-center-h-v">
        <v-progress-circular
          :size="32"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- CONFIRMATION MESSAGE -->
        <div v-if="message">
          <p :style="{ color: messageStatus }">{{ this.message }}</p>
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
            small
            @click="cancelForm"
          >
            FERMER
          </v-btn>
        </div>
        <!-- RESERVATION FORM -->
        <div v-else>
          <!-- CALENDAR SELECTION -->
          <v-row>
            <div class="period-selector">
              <div>
                <label for="start-date">Du</label>
                <input
                  class="custom-date-picker"
                  name="start-date"
                  type="date"
                  :min="today()"
                  v-model="startDate"
                />
                <v-radio-group v-model="startAmPm">
                  <v-radio label="AM" value="am"></v-radio>
                  <v-radio label="PM" value="pm"></v-radio>
                </v-radio-group>
              </div>
              <div>
                <label for="end-date">Au</label>
                <input
                  class="custom-date-picker"
                  name="end-date"
                  type="date"
                  :min="startDate"
                  v-model="endDate"
                />
                <v-radio-group v-model="endAmPm">
                  <v-radio
                    label="AM"
                    value="am"
                    :disabled="!canReserveAm"
                  ></v-radio>
                  <v-radio label="PM" value="pm"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-row>
          <v-row v-if="getSelectedUserVehicleList">
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Séléction du véhicule</label
              >
              <v-autocomplete
                v-model="selectedVehicle"
                :items="getSelectedUserVehicleList"
                item-text="platenumber"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mt-2
                  mb-0
                "
                return-object
                outlined
                single-line
                height="36"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Parking</label
              >
              <v-select
                v-model="selectedParking"
                :items="userParkings"
                item-text="name"
                item-value="id"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- BUTTONS ACTION -->
          <v-row class="bottom-action-btn">
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="cancelForm"
            >
              ANNULER
            </v-btn>
            <v-btn
              :elevation="0"
              color="#cb0c9f"
              class="
                font-weight-bolder
                btn-danger
                bg-gradient-info
                py-4
                px-2
                mt-auto
              "
              small
              :disabled="!canReserve"
              @click="createReservation"
            >
              Réserver
            </v-btn>
          </v-row>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<script>
import axios from "axios";
import { reservation400 } from "../../util/reservation400";

export default {
  name: "UniqueReservation",

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pendingRequest: false,
      date: null,
      startDate: null,
      startAmPm: "am",
      endDate: null,
      endAmPm: "am",
      selectedUser: null,
      userVehicleList: null,
      selectedVehicle: null,
      selectedParking: null,
      message: null,
      messageStatus: null,
      userParkingsId: [],
      userParkings: null,
    };
  },

  beforeMount() {
    this.selectedVehicle = this.user.vehicles ? this.user?.vehicles[0] : null;
    this.pendingRequest = true;
    axios
      .get(`${this.$store.getters.apiurl}/parkings`)
      .then((res) => {
        this.message = null;
        let userParkingsId = [this.user.mainparking];
        if (this.user.secondaryparking) {
          userParkingsId = [...userParkingsId, ...this.user.secondaryparking];
        }
        this.userParkingsId = userParkingsId;
        this.userParkings = res.data.filter((parking) =>
          userParkingsId.includes(parking.id)
        );
        this.selectedParking = this.user.mainparking || null;
        this.pendingRequest = false;
      })
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Fetch parkings ERROR", err);
      });
  },

  computed: {
    canReserveAm() {
      return (
        new Date(this.endDate) > new Date(this.startDate) ||
        (this.startDate === this.endDate && this.startAmPm === "am")
      );
    },

    getSelectedUserVehicleList() {
      if (!this.user) {
        return null;
      }
      return this.user?.vehicles || null;
    },

    canReserve() {
      return (
        this.startDate && this.endDate && this.user.id && this.selectedParking
      );
    },
  },

  watch: {
    startDate(nVal) {
      if (new Date(nVal) > new Date(this.endDate)) {
        this.endDate = nVal;
      }

      if (nVal === this.endDate && this.startAmPm === "pm") {
        this.endAmPm = "pm";
      }
    },

    startAmPm(nVal) {
      if (this.startDate === this.endDate && nVal === "pm") {
        this.endAmPm = "pm";
      }
    },

    user(nVal) {
      console.log("user nval: ", nVal);
      this.selectedVehicle = nVal?.vehicles[0];
    },
  },

  methods: {
    /**
     * TODAY
     * Return the current date used in min/max input date
     * @returns {string} today formated yyyy-mm-dd -> '2022-01-28'
     */
    today() {
      const today = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
      return today;
    },

    cancelForm() {
      this.startDate = null;
      this.startAmPm = "am";
      this.endDate = null;
      this.endAmPm = "am";
      this.selectedUser = null;
      this.vehicles = null;
      this.selectedParking = null;
      this.message = null;
      this.$emit("cancel");
    },

    buildUserVehicleList(plates) {
      if (!plates || plates.length === 0) {
        return [];
      }
      const vehicleName = {
        cars: "Voiture",
        motorbike: "Moto",
        bike: "Vélo",
        default: "Véhicule",
      };

      let vehicleList = [];
      const vehicleTypes = Object.keys(plates);
      vehicleTypes.forEach((k) => {
        const typeColl = plates[k].map((immat) => {
          return {
            type: k,
            immat: immat,
            content: `${vehicleName[k] ?? vehicleName.default} immat: ${immat}`,
          };
        });
        vehicleList = [...vehicleList, ...typeColl];
      });
      return vehicleList;
    },

    /**
     * CREATE RESERVATION
     */
    createReservation() {
      this.pendingRequest = true;
      this.message = null;
      const reservation = {
        userid: this.user.id,
        initdate: this.startDate,
        initperiod: this.startAmPm,
        enddate: this.endDate,
        endperiod: this.endAmPm,
        parkingid: this.selectedParking,
        vehicleid: this.selectedVehicle?.id || "",
      };
      // -- Clean reservation body before POST
      for (const [key, value] of Object.entries(reservation)) {
        if (value === undefined || value === "") {
          delete reservation[key];
        }
      }
      axios
        .post(`${this.$store.getters.apiurl}/reservations`, reservation)
        .then((res) => {
          this.pendingRequest = false;
          if (res.status === 201) {
            this.message = "Réservation effectuée";
            this.messageStatus = "green";
          } else {
            this.messageStatus = "orange";
            this.message = `Reservation effectuée (${res.data})`;
          }
        })
        .catch((err) => {
          this.pendingRequest = false;
          if (err?.response?.status == 400 || err?.status == 400) {
            this.message = reservation400(err?.response?.data || err?.data)[
              "fr"
            ];
          } else if (err?.response?.status == 404 || err?.status == 404) {
            this.message = `Reservation non effectuée. Parking et/ou utilisateur non trouvé.`;
          } else {
            this.message = `Reservation non effectuée.`;
          }
          this.messageStatus = "red";
        });
    },
  },
};
</script>

<style scoped>
.custom-date-picker {
  height: 36px;
  margin-left: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

.period-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  gap: 2rem;
  margin-left: 1rem;
}

.bottom-action-btn {
  display: flex;
  justify-content: space-between;
  padding-inline: 1rem;
}

@media screen and (max-width: 640px) {
  .period-selector {
    gap: 1rem;
  }
}
</style>
