<template>
  <div>
    <div class="grid-reservation">
      <!-- GRID LEGEND -->
      <div
        class="text-sm text-body text-left p-4"
        style="
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          grid-template-columns: 10ch;
        "
      >
        <div></div>
        <div style="align-self: center">LE MATIN</div>
        <div style="align-self: center">L'APRÈS MIDI</div>
      </div>
      <!-- RESERVATION GRID -->
      <div v-if="loadingReservations" class="grid-center-h-v">
        <v-progress-circular
          :size="32"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else class="grid-reservation" style="overflow-x: auto">
        <div
          v-for="(day, idx) in dataset"
          :key="idx"
          class="day-block"
          style="min-width: 10ch"
        >
          <div class="text-sm text-body text-center">
            {{ getReadableDay(getFormatedDate(day.date)) }}
          </div>
          <div class="text-sm text-body text-center">
            {{ getDayMonth(day.date) }}
          </div>
          <div class="dflex flexcol">
            <GridReservationBtn
              btnContent="AM"
              :btnState="getBtnState(day, 'am')"
              :info="day"
              @clicked="updateReservation($event, 'AM')"
              style="margin-bottom: 6px"
            ></GridReservationBtn>
            <GridReservationBtn
              btnContent="PM"
              :btnState="getBtnState(day, 'pm')"
              :info="day"
              @clicked="updateReservation($event, 'PM')"
            ></GridReservationBtn>
          </div>
        </div>
      </div>
    </div>
    <div v-if="message" class="error-message-box">
      <p class="text-center text-xs">
        {{ this.message }}
      </p>
      <div class="close-cross" @click="message = ''"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GridReservationBtn from "./GridReservationBtn.vue";
import { reservation400 } from "../../util/reservation400";

export default {
  name: "GridReservation",
  components: {
    GridReservationBtn,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingReservations: false,
      disponibilities: [],
      dataset: [],
      message: "",
    };
  },

  created() {
    this.fetchReservations(this.user.mainparking);
  },

  methods: {
    updateReservation(evt, period) {
      const periodParam = period.toLowerCase();
      const selectedDate = evt.date ? evt.date : evt.info.date;
      const getIndexToUpdate = this.dataset.findIndex(
        (elm) => elm.date === selectedDate
      );

      if (getIndexToUpdate < 0) return;

      // -- CANCEL RESERVATION
      if (evt["info"] && evt["info"][periodParam]["reserved"]) {
        const resInfo = evt["info"][periodParam]["reserved"];
        const resaid = evt["info"][periodParam]["reserved"]["resaid"];
        this.dataset[getIndexToUpdate][periodParam]["reserved"] = "loading";
        axios
          .delete(`${this.$store.getters.apiurl}/reservations/${resaid}`)
          .then((res) => {
            if (res.status === 201) {
              this.dataset[getIndexToUpdate][period.toLowerCase()]["reserved"] =
                undefined;
              this.$emit("updateReservations");
            }
          })
          .catch((err) => {
            console.error("cancelReservation ERROR", err);
            this.dataset[getIndexToUpdate][period.toLowerCase()]["reserved"] =
              resInfo;
          });
      } else {
        // -- CREATE RESERVATION
        const btnToRes = this.dataset[getIndexToUpdate];
        this.dataset[getIndexToUpdate][periodParam]["reserved"] = "loading";
        axios
          .post(`${this.$store.getters.apiurl}/reservations`, {
            userid: this.user.id,
            initdate: btnToRes.date.split("/").reverse().join("-"),
            initperiod: periodParam,
            enddate: btnToRes.date.split("/").reverse().join("-"),
            endperiod: periodParam,
            parkingid: this.user.mainparking,
          })
          .then((res) => {
            this.dataset[getIndexToUpdate][periodParam]["reserved"] = res.data;
            this.$emit("updateReservations");
          })
          .catch((err) => {
            console.error("Error during reservation: ", err);
            this.messageStatus = "red";
            this.dataset[getIndexToUpdate][periodParam]["reserved"] = undefined;
            if (err?.response?.status == 400) {
              this.message = reservation400(err.response.data)["fr"];
            } else if (err?.response?.status == 404) {
              this.message = `Reservation non effectuée. Parking et/ou utilisateur non trouvé.`;
            } else {
              this.message = `Reservation non effectuée.`;
            }
          });
      }
    },

    /**
     * CREATE RESERVATION
     */
    createReservation() {
      this.pendingRequest = true;
      this.message = null;
      axios
        .post(`${this.$store.getters.apiurl}/reservationsparking`, {
          userid: this.selectedUser,
          initdate: this.startDate,
          initperiod: this.startAmPm,
          enddate: this.endDate,
          endperiod: this.endAmPm,
          parkingid: this.selectedParking,
        })
        .then((res) => {
          this.pendingRequest = false;
          if (res.status === 201) {
            this.message = "Réservation effectuée";
            this.messageStatus = "green";
          } else {
            this.messageStatus = "orange";
            this.message = `Reservation effectuée (${res.data})`;
          }
        })
        .catch((err) => {
          this.pendingRequest = false;
          if (err.response.status == 400) {
            this.message = reservation400(err.response.data)["fr"];
          } else if (err.response.status == 404) {
            this.message = `Reservation non effectuée. Parking et/ou utilisateur non trouvé.`;
          } else {
            this.message = `Reservation non effectuée.`;
          }
          this.messageStatus = "red";
          console.error("Create reservation ERROR: ", err.response);
        });
    },

    /**
     * GET BTN STATE
     * -------------------------------------------------------------------------
     * Take Object day {AM: 'reserved', pm: 'available'} and period 'AM' | 'PM'
     * and return the status of the button
     * -------------------------------------------------------------------------
     * @param {Object} day
     * @param {String | 'AM'} period
     * @returns {'loading' | 'reserved' | 'available' | 'unavailable'}
     */

    getBtnState(day, period) {
      // loading reserved available unavailable
      if (day[period]["reserved"] === "loading") {
        return "loading";
      }
      if (day[period]["reserved"] !== undefined) {
        return "reserved";
      }
      if (day[period]["available"] > 0) {
        return "available";
      }
      if (day[period]["available"] === 0) {
        return "unavailable";
      }
    },

    /**
     * GET FORMATED DATE
     * -------------------------------------------------------------------------
     * Take date string format like "dd/mm/yyyy" and return a Date JS Object
     * -------------------------------------------------------------------------
     * @param {String} date format '12/01/2022' dd/mm/yyyy
     * @returns {Date}
     */
    getFormatedDate(date) {
      const splitted = date.split("/");
      return new Date(`${splitted[1]}-${splitted[0]}-${splitted[2]}`);
    },

    /**
     * GET READABLE DAY
     * -------------------------------------------------------------------------
     * This function take Date JS object as input and return the day abbrevation
     * as string in french like "LUN" for "monday" etc
     * -------------------------------------------------------------------------
     * @param {Date} date
     * @returns {String}
     */
    getReadableDay(date) {
      const weekDays = ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"];
      const day = date.getDay();
      return weekDays[day];
    },

    /**
     * GET DAY MONTH
     * -------------------------------------------------------------------------
     * This is a converter function. Take "dd/mm/yy" and return "dd/mm"
     * -------------------------------------------------------------------------
     * @param {String} date format 'dd/mm/yyyy'
     * @returns {String} 'dd/mm'
     */
    getDayMonth(date) {
      const splitted = date.split("/");
      return `${splitted[0]}/${splitted[1]}`;
    },

    /**
     * SORT DISPONIBILITIES
     * -------------------------------------------------------------------------
     * Sort disponibilities in chronological order
     * -------------------------------------------------------------------------
     * @param {DISPONIBILITIES[]} disponibilities collection
     * @returns {DISPONIBILITIES[]} disponibilities collection sorted by date
     */
    sortDisponibilities(dispo) {
      return dispo.sort((a, b) => new Date(a.date) - new Date(b.date));
    },

    /**
     * FETCH RESERVATIONS
     * -------------------------------------------------------------------------
     * This function fetch datas for the reservation grid.
     * We need two elements:
     * 1. The user reservations already taken
     * 2. The main parking disponibilities
     *
     * Then build the reservation grid dataset.
     * -------------------------------------------------------------------------
     * @param {string} parkingId
     */
    fetchReservations(parkingId) {
      const disponibilities = axios.get(
        `${this.$store.getters.apiurl}/disponibilities/${parkingId}`
      );

      let dateSt = this.getStartEndDay();
      const period = `?init=${dateSt.today}&end=${dateSt.nextDay}`;
      const reservations = axios.get(
        `${this.$store.getters.apiurl}/reservations${period}`
      );

      this.loadingReservations = true;
      axios
        .all([disponibilities, reservations])
        .then(
          axios.spread((...responses) => {
            const responseDisponibilities = responses[0].data;
            let disponibilitiesSorted = this.sortDisponibilities(
              responseDisponibilities
            );
            const responseReservations = responses[1].data;
            this.dataset = this.buildReservationGrid(
              disponibilitiesSorted,
              responseReservations
            );
            this.loadingReservations = false;
          })
        )
        .catch((errors) => {
          this.loadingReservations = false;
          console.error(errors);
        });
    },

    /**
     * BUILD RESERVATION GRID
     * -------------------------------------------------------------------------
     * This function take disponibilities & user reservations and return
     * the reservation grid dataset.
     *  {
          date: any;
            am: {
                reserved: any;
                available: any;
            };
            pm: {
                reserved: any;
                available: any;
            };
        }[]
     * -------------------------------------------------------------------------
     * @param {Array} disponibilities
     * @param {Array} userReservations
     * @returns {Array} reservation grid dataset
     */
    buildReservationGrid(disponibilities, userReservations) {
      let reservationGrid = disponibilities.map((dispo) => {
        let reservationAm = userReservations.find((r) => {
          return dispo.date === r.initdate && r.initperiod === "am";
        });
        let reservationPm = userReservations.find((r) => {
          return dispo.date === r.initdate && r.initperiod === "pm";
        });

        return {
          date: dispo.date.split("-").reverse().join("/"),
          am: {
            reserved: reservationAm,
            available: dispo.am,
          },
          pm: {
            reserved: reservationPm,
            available: dispo.pm,
          },
        };
      });
      return reservationGrid;
    },

    /**
     * GET START END DAY
     * -------------------------------------------------------------------------
     * This function return an object with the current date
     * and a date with a gap of days.
     * -------------------------------------------------------------------------
     * @param {Number} nbDayGap - The number of day after the current time
     * @returns (Object) today, nextDay - format "dd-mm-yy"
     */
    getStartEndDay(nbDayGap = 30) {
      let today = new Date().getTime();
      let nextDay = today + 3600000 * 24 * nbDayGap;
      const todayDate = {
        day: ("0" + new Date(today).getDate()).slice(-2),
        month: ("0" + (new Date(today).getMonth() + 1)).slice(-2),
        year: new Date(today).getFullYear(),
      };
      const nextDayDate = {
        day: ("0" + new Date(nextDay).getDate()).slice(-2),
        month: ("0" + (new Date(nextDay).getMonth() + 1)).slice(-2),
        year: new Date(nextDay).getFullYear(),
      };

      return {
        today: `${todayDate.day}-${todayDate.month}-${todayDate.year}`,
        nextDay: `${nextDayDate.day}-${nextDayDate.month}-${nextDayDate.year}`,
      };
    },
  },
};
</script>

<style scoped>
.grid-reservation {
  display: flex;
  gap: 6px;
}

.day-block {
  padding: 0px;
}

.error-message-box {
  margin-top: 1rem;
  padding: 0.5rem;
  background: #f44336;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.error-message-box > p {
  color: #ffffff;
  padding: 0 0 0 1rem;
  margin: 0;
}

.close-cross {
  --b: 2px; /* the thickness*/
  --c: transparent 90deg, #fff 0; /* the coloration */
  width: calc(var(--b) * 7); /* the size */
  aspect-ratio: 1/1;
  background: conic-gradient(from 90deg at var(--b) var(--b), var(--c))
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));

  display: inline-block;
  margin: var(--b);
  vertical-align: middle;
  margin-left: 6px;
  transform: rotate(45deg);
}

.close-cross:hover {
  cursor: pointer;
}
</style>
