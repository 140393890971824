<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="periodicReservationList"
      fixed-header
      class="table"
      hide-default-footer
      mobile-breakpoint="0"
      :items-per-page="periodicReservationList.length"
    >
      <template v-slot:[`item.day`]="{ item }">
        <p class="text-sm font-weight-bold mt-4 mb-0">
          {{ getDay(item.day) || "n/c" }}
        </p>
      </template>

      <template v-slot:[`item.period`]="{ item }">
        <div>
          <p class="text-sm text-secondary font-weight-bold mt-4 mb-0">
            {{ getPeriod(item.period) }}
          </p>
        </div>
      </template>

      <template v-slot:[`item.repetitionperiod`]="{ item }">
        <div>
          <p class="text-sm text-secondary font-weight-bold mt-4 mb-0">
            {{ getRepetionPeriod(item.repetitionperiod) }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <p
          class="mt-4 p-0 text-blue"
          style="text-decoration: underline; cursor: pointer"
          @click="deleteReservation(item)"
        >
          Supprimer
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PeriodicReservationTable",

  data() {
    return {
      periodicReservationList: [],
      headers: [
        {
          text: "Jour",
          align: "start",
          sortable: true,
          value: "day",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Période",
          value: "period",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Période de répétition",
          value: "repetitionperiod",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },

  beforeMount() {
    this.getPeriodicReservations();
  },

  methods: {
    getPeriodicReservations() {
      axios
        .get(`${this.$store.getters.apiurl}/periodicreservations`)
        .then((res) => {
          this.periodicReservationList = res.data;
          return res.data;
        })
        .catch((err) => {
          console.error("Error get periodic reservations: ", err);
        });
    },

    getDay(day) {
      const weekDays = {
        mon: "Lundi",
        tue: "Mardi",
        wed: "Mercredi",
        thu: "Jeudi",
        fri: "Vendredi",
        sat: "Samedi",
        sun: "Dimanche",
      };
      return weekDays[day];
    },

    getPeriod(period) {
      const periodList = {
        am: "Uniquement le matin",
        pm: "Uniquement l'après-midi",
        all: "Toute la journée",
      };
      return periodList[period];
    },

    getRepetionPeriod(period) {
      const repetionPeriodList = {
        "1W": "Chaque semaine",
        "2W": "Une semaine sur deux",
      };
      return repetionPeriodList[period];
    },

    deleteReservation(resa) {
      axios
        .delete(
          `${this.$store.getters.apiurl}/periodicreservations/${resa.resaid}`
        )
        .then(() => {
          this.getPeriodicReservations();
        })
        .catch((err) => {
          console.err("ERROR on deleteReservation: ", err);
        });
    },
  },
};
</script>

<style scoped></style>
