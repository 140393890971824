<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on }">
        <v-btn
          :elevation="0"
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-danger
            bg-gradient-info
            py-4
            px-8
            mt-auto
          "
          small
          v-on="on"
        >
          INVITER
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> CRÉER UNE INVITATION </v-card-title>
        <v-card-text v-if="loading">
          <div class="grid-center-h-v">
            <v-progress-circular
              :size="32"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <!-- CONFIRMATION MESSAGE -->
          <div v-if="message">
            <p :style="{ color: messageStatus }">{{ message }}</p>
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="sendMail"
            >
              ENVOYER LE MAIL D'INVITATION
            </v-btn>
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="closeDialog"
            >
              FERMER
            </v-btn>
          </div>
          <!-- FORM -->
          <div v-else>
            <!-- CALENDAR SELECTION -->
            <v-row>
              <div class="period-selector">
                <div>
                  <label for="start-date">Du</label>
                  <input
                    class="custom-date-picker"
                    name="start-date"
                    type="date"
                    :min="today()"
                    v-model="initdate"
                  />
                  <v-radio-group v-model="startAmPm">
                    <v-radio label="AM" value="am"></v-radio>
                    <v-radio label="PM" value="pm"></v-radio>
                  </v-radio-group>
                </div>
                <div>
                  <label for="end-date">Au</label>
                  <input
                    class="custom-date-picker"
                    name="end-date"
                    type="date"
                    :min="initdate"
                    v-model="enddate"
                  />
                  <v-radio-group v-model="endAmPm">
                    <v-radio
                      label="AM"
                      value="am"
                      :disabled="!canReserveAm"
                    ></v-radio>
                    <v-radio label="PM" value="pm"></v-radio>
                  </v-radio-group>
                </div>
              </div>
            </v-row>
            <!-- PARKING SELECTION -->
            <v-row>
              <v-col cols="12">
                <label class="text-xs text-typo font-weight-bolder ms-1"
                  >PARKING</label
                >
                <v-select
                  v-model="parkingid"
                  :items="parkings"
                  item-text="name"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                  "
                  outlined
                  single-line
                  height="36"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- INVIT EMAIL -->
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >email</label
            >
            <v-text-field
              v-model="email"
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder=""
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                text-light-input
                mt-2
              "
            >
            </v-text-field>
            <!-- INVIT LASTNAME -->
            <label class="text-xs text-typo font-weight-bolder ms-1">NOM</label>
            <v-text-field
              v-model="name"
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder=""
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                text-light-input
                mt-2
              "
            >
            </v-text-field>
            <!-- INVIT FIRSTNAME -->
            <label class="text-xs text-typo font-weight-bolder ms-1"
              >PRENOM</label
            >
            <v-text-field
              v-model="surname"
              hide-details
              outlined
              color="rgba(0,0,0,.6)"
              light
              placeholder=""
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                text-light-input
                mt-2
              "
            >
            </v-text-field>
            <!-- SAVE OR CANCEL -->
            <div class="d-flex justify-end mt-8">
              <v-btn
                elevation="0"
                color="#fff"
                class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
                small
                @click="closeDialog"
              >
                ANNULER
              </v-btn>
              <v-btn
                :elevation="0"
                color="#cb0c9f"
                class="
                  font-weight-bolder
                  btn-danger
                  bg-gradient-info
                  py-4
                  px-2
                  mt-auto
                "
                small
                :disabled="!canCreateInvit"
                @click="createInvit"
              >
                INVITER
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InvitationForm",

  data() {
    return {
      dialog: false,
      loading: false,
      message: null,
      messageStatus: null,
      parkings: [],
      startAmPm: "am",
      endAmPm: "am",
      email: null, // "invit@invit.com"
      initdate: null, // "2022-02-14"
      enddate: null, // "2022-02-14"
      initperiod: null, // "am"
      endperiod: null, // "pm"
      name: null, // "Guest"
      surname: null, // "GUEST"
      parkingid: null, // "0"
      invit: null,
    };
  },

  beforeMount() {
    this.loading = true;
    axios
      .get(`${this.$store.getters.apiurl}/parkings`)
      .then((res) => {
        this.parkings = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.error("Error on fetch parkings: ", err);
      });
  },

  computed: {
    canReserveAm() {
      return (
        new Date(this.enddate) > new Date(this.initdate) ||
        (this.initdate === this.enddate && this.startAmPm === "am")
      );
    },
    canCreateInvit() {
      return (
        this.email &&
        this.initdate &&
        this.enddate &&
        this.name &&
        this.surname &&
        this.parkingid
      );
    },
  },

  watch: {
    initdate(nVal) {
      if (new Date(nVal) > new Date(this.enddate)) {
        this.enddate = nVal;
      }

      if (nVal === this.enddate && this.startAmPm === "pm") {
        this.endAmPm = "pm";
      }
    },

    startAmPm(nVal) {
      if (this.initdate === this.enddate && nVal === "pm") {
        this.endAmPm = "pm";
      }
    },
  },

  methods: {
    /**
     * TODAY
     * Return the current date used in min/max input date
     * @returns {string} today formated yyyy-mm-dd -> '2022-01-28'
     */
    today() {
      const today = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
      return today;
    },

    closeDialog() {
      this.email = null;
      this.initdate = null;
      this.enddate = null;
      this.initperiod = null;
      this.endperiod = null;
      this.name = null;
      this.surname = null;
      this.parkingid = null;
      this.dialog = false;
    },

    createInvit() {
      const body = {
        email: this.email.trim(),
        initdate: this.initdate,
        enddate: this.enddate,
        initperiod: this.startAmPm,
        endperiod: this.endAmPm,
        name: this.name.trim(),
        surname: this.surname.trim(),
        parkingid: this.parkingid,
      };
      this.loading = true;
      console.log("BODY TO INVIT: ", body);
      axios
        .post(`${this.$store.getters.apiurl}/invit/`, body)
        .then((res) => {
          this.message = "Invitation en attente";
          this.messageStatus = "green";
          this.loading = false;
          this.invit = res.data;
          console.log("POST INVIT RES", res);
          this.$emit("updateInvitations");
        })
        .catch((err) => {
          this.loading = false;
          this.message = "L' enregistrement à échoué";
          this.messageStatus = "red";
          console.error("Errorn on post invit", err);
        });
    },

    sendMail() {
      const invit = this.invit;
      console.log(invit);
      let subject = "Votre invitation";
      const inviteURL = `${this.$store.getters.apiurl.replace(
        "/v2",
        ""
      )}/invitations/${invit.id}`;
      console.log("inviteURL: ", inviteURL);
      //this.userinvitationUrl = `${this.invitpage}${invit.id}?timestp=${invit.timestp}`;
      document.location = `mailto:${
        invit.email
      }?subject=${subject}&body=${encodeURIComponent(
        this.emailbody(invit, inviteURL)
      )}`;
      this.getInvitations();
    },

    emailbody(invit, inviteURL) {
      return `Cher ${invit.surname.toUpperCase()} ${invit.name}:
        \n\rLa société ${this.$store.getters.enterprise.toUpperCase()}
         vous invite à réserver votre place pour votre rendez-vous du ${invit.initdate
           .split("-")
           .reverse()
           .join("/")}.
        \n\rMerci de renseigner les éléments demandés dans le lien suivant: ${inviteURL}
        \n\rÀ votre arrivée sur site, la reconnaissance de votre plaque d'immatriculation permettra l'ouverture de la porte (délai d’ouverture de 4 secondes à prendre en compte).
        \n\rBonne journée.`;
    },
  },
};
</script>

<style scoped>
.custom-date-picker {
  height: 36px;
  margin-left: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

.period-selector {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  gap: 2rem;
  margin-left: 1rem;
}
</style>
