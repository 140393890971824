<template>
  <div>
    <v-btn
      v-if="getState(btnState) === 'LOADING'"
      :elevation="0"
      block
      class="font-weight-bolder py-4 px-2 mt-auto"
      large
    >
      <v-progress-circular
        :size="16"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-btn>
    <v-btn
      v-else-if="getState(btnState) === 'RESERVED'"
      color="success"
      :elevation="0"
      block
      class="font-weight-bolder py-4 bg-gradient-info px-2 mt-auto"
      large
      @click="$emit('clicked', { info, period: btnContent.toLowerCase() })"
    >
      {{ btnContent }}
      <div class="close-cross" style="transform: rotate(45deg)"></div>
    </v-btn>
    <v-btn
      v-else-if="getState(btnState) === 'AVAILABLE'"
      :elevation="0"
      block
      class="font-weight-bolder py-4 px-2 mt-auto"
      large
      @click="$emit('clicked', info)"
    >
      {{ btnContent }}
      <div class="close-cross" style="opacity: 0"></div>
    </v-btn>
    <v-btn
      v-else
      :elevation="0"
      block
      class="font-weight-bolder py-4 px-2 mt-auto"
      large
      style="opacity: 0.6"
      disabled
    >
      {{ btnContent }}
    </v-btn>
  </div>
</template>

<script>
const BTN_STATE = {
  reserved: "RESERVED",
  available: "AVAILABLE",
  unavailable: "UNAVAILABLE",
  loading: "LOADING",
};

export default {
  name: "GridReservationBtn",
  props: {
    btnContent: {
      type: [String, Number],
      default: "Btn Gradient",
    },
    info: {
      type: Object,
      default: null,
    },
    btnState: {
      type: String,
      default: null,
    },
  },
  methods: {
    getState(state) {
      return BTN_STATE[state] || BTN_STATE["unavailable"];
    },
  },
};
</script>

<style scoped>
.close-cross {
  --b: 2px; /* the thickness*/
  --c: transparent 90deg, #fff 0; /* the coloration */
  width: calc(var(--b) * 7); /* the size */
  aspect-ratio: 1/1;
  background: conic-gradient(from 90deg at var(--b) var(--b), var(--c))
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));

  display: inline-block;
  margin: var(--b);
  vertical-align: middle;
  margin-left: 6px;
}
</style>
