<template>
  <v-card-text>
    <div>
      <div v-if="pendingRequest" class="grid-center-h-v">
        <v-progress-circular
          :size="32"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <!-- CONFIRMATION MESSAGE -->
        <div v-if="message">
          <p :style="{ color: messageStatus }">{{ this.message }}</p>
          <v-btn
            elevation="0"
            color="#fff"
            class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
            small
            @click="cancelForm"
          >
            FERMER
          </v-btn>
        </div>
        <!-- RESERVATION FORM -->
        <div v-else>
          <!-- PARKING SELECTION -->
          <v-row>
            <v-col cols="12">
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Selectionner un parking</label
              >
              <v-select
                v-model="selectedParking"
                :items="userParkings"
                item-text="name"
                item-value="id"
                color="rgba(0,0,0,.6)"
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                "
                outlined
                single-line
                height="36"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <label
              class="text-xs text-typo font-weight-bolder ms-1"
              style="padding: 12px"
              >Sélectionner les jours</label
            >
            <div class="weekday-wrapper">
              <button
                v-for="day in weekDay"
                :key="day.value"
                class="weekDay"
                :class="day.selected ? 'weekDay-selected' : ''"
                @click="updateDaySelection(day.value)"
              >
                {{ day.text }}
              </button>
            </div>
          </v-row>
          <v-row class="radio-options">
            <div>
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Sélectionner la periode</label
              >
              <v-radio-group v-model="period">
                <v-radio label="Uniquement le matin" value="am"></v-radio>
                <v-radio label="Uniquement l’après midi" value="pm"></v-radio>
                <v-radio label="Toute la journée" value="all"></v-radio>
              </v-radio-group>
            </div>
            <div>
              <label class="text-xs text-typo font-weight-bolder ms-1"
                >Sélectionner la fréquence</label
              >
              <v-radio-group v-model="repetitionperiod">
                <v-radio label="Chaque semaine" value="1W"></v-radio>
                <v-radio label="Une semaine sur 2" value="2W"></v-radio>
              </v-radio-group>
            </div>
          </v-row>

          <!-- BUTTONS ACTION -->
          <v-row class="bottom-action-btn">
            <v-btn
              elevation="0"
              color="#fff"
              class="font-weight-bolder btn-outline-default py-4 px-2 mr-4"
              small
              @click="cancelForm"
            >
              ANNULER
            </v-btn>
            <v-btn
              :elevation="0"
              color="#cb0c9f"
              class="
                font-weight-bolder
                btn-danger
                bg-gradient-info
                py-4
                px-2
                mt-auto
              "
              small
              :disabled="!canReserve"
              @click="createReservation"
            >
              Réserver
            </v-btn>
          </v-row>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<script>
import axios from "axios";
import { reservation400 } from "../../util/reservation400";

export default {
  name: "PeriodiqueReservationForm",

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pendingRequest: false,
      period: "am", // -- "am "pm" "all"
      repetitionperiod: "1W", // -- "1w" "2w"
      day: [],
      selectedUser: null,
      selectedParking: null,
      message: null,
      messageStatus: null,
      userParkingsId: [],
      userParkings: null,
      weekDay: [
        {
          text: "Lundi",
          value: "mon",
          selected: false,
        },
        {
          text: "Mardi",
          value: "tue",
          selected: false,
        },
        {
          text: "Mercredi",
          value: "wed",
          selected: false,
        },
        {
          text: "Jeudi",
          value: "thu",
          selected: false,
        },
        {
          text: "Vendredi",
          value: "fri",
          selected: false,
        },
        {
          text: "Samedi",
          value: "sat",
          selected: false,
        },
        {
          text: "Dimanche",
          value: "sun",
          selected: false,
        },
      ],
    };
  },

  beforeMount() {
    this.pendingRequest = true;
    axios
      .get(`${this.$store.getters.apiurl}/parkings`)
      .then((res) => {
        this.message = null;
        let userParkingsId = [this.user.mainparking];
        if (this.user.secondaryparking) {
          userParkingsId = [...userParkingsId, ...this.user.secondaryparking];
        }
        this.userParkingsId = userParkingsId;
        this.userParkings = res.data.filter((parking) =>
          userParkingsId.includes(parking.id)
        );
        this.selectedParking = this.user.mainparking || null;
        this.pendingRequest = false;
      })
      .catch((err) => {
        this.pendingRequest = false;
        console.error("Fetch parkings ERROR", err);
      });
  },

  computed: {
    canReserve() {
      return (
        this.selectedParking && this.weekDay.find((d) => d.selected == true)
      );
    },
  },

  watch: {
    startDate(nVal) {
      if (new Date(nVal) > new Date(this.endDate)) {
        this.endDate = nVal;
      }

      if (nVal === this.endDate && this.startAmPm === "pm") {
        this.endAmPm = "pm";
      }
    },

    startAmPm(nVal) {
      if (this.startDate === this.endDate && nVal === "pm") {
        this.endAmPm = "pm";
      }
    },

    selectedUser() {
      this.selectedVehicle = null;
    },
  },

  methods: {
    /**
     * TODAY
     * Return the current date used in min/max input date
     * @returns {string} today formated yyyy-mm-dd -> '2022-01-28'
     */
    today() {
      const today = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
      return today;
    },

    updateDaySelection(day) {
      const dayIdx = this.weekDay.findIndex((d) => d.value === day);
      this.weekDay[dayIdx].selected = !this.weekDay[dayIdx].selected;
    },

    cancelForm() {
      this.startDate = null;
      this.startAmPm = "am";
      this.endDate = null;
      this.endAmPm = "am";
      this.selectedUser = null;
      this.vehicles = null;
      this.selectedParking = null;
      this.message = null;
      this.$emit("cancel");
    },

    buildUserVehicleList(plates) {
      if (!plates || plates.length === 0) {
        return [];
      }
      const vehicleName = {
        cars: "Voiture",
        motorbike: "Moto",
        bike: "Vélo",
        default: "Véhicule",
      };

      let vehicleList = [];
      const vehicleTypes = Object.keys(plates);
      vehicleTypes.forEach((k) => {
        const typeColl = plates[k].map((immat) => {
          return {
            type: k,
            immat: immat,
            content: `${vehicleName[k] ?? vehicleName.default} immat: ${immat}`,
          };
        });
        vehicleList = [...vehicleList, ...typeColl];
      });
      return vehicleList;
    },

    /**
     * CREATE RESERVATION
     */
    createReservation() {
      this.pendingRequest = true;
      this.message = null;
      axios
        .post(`${this.$store.getters.apiurl}/periodicreservations`, {
          period: this.period,
          repetitionperiod: this.repetitionperiod,
          day: this.weekDay
            .filter((day) => day.selected)
            .map((elm) => elm.value),
          parkingid: this.selectedParking,
        })
        .then((res) => {
          this.pendingRequest = false;
          if (res.status === 201) {
            this.message = "Réservation périodique effectuée";
            this.messageStatus = "green";
          } else {
            this.messageStatus = "orange";
            this.message = `Reservation périodique effectuée (${res.data})`;
          }
        })
        .catch((err) => {
          this.pendingRequest = false;
          if (err.response.status == 400) {
            this.message = reservation400(err.response.data)["fr"];
          } else if (err.response.status == 404) {
            this.message = `Reservation non effectuée. Parking et/ou utilisateur non trouvé.`;
          } else {
            this.message = `Reservation non effectuée.`;
          }
          this.messageStatus = "red";
          console.error("Create reservation ERROR: ", err.response);
        });
    },

    getResPeriodique() {
      axios
        .get(`${this.$store.getters.apiurl}/periodicreservations`)
        .then((res) => {
          console.log("getResPeriodique res: ", res);
        });
    },
  },
};
</script>

<style scoped>
.custom-date-picker {
  height: 36px;
  margin-left: 1rem;
  border: 1px solid #dee2e6;
  padding: 0 16px;
  border-radius: 8px;
  color: #8994a8;
}

.period-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  gap: 2rem;
  margin-left: 1rem;
}

.bottom-action-btn {
  display: flex;
  justify-content: space-between;
  padding-inline: 1rem;
}

.weekday-wrapper {
  padding: 0 12px 12px 12px;
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(7, 1fr);
}

.weekDay {
  background: #ffffff;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  color: #8994a8;
}

.weekDay-selected {
  background: #24c3fd;
  color: #ffffff;
}

.radio-options {
  width: 100%;
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 640px) {
  .period-selector {
    gap: 1rem;
  }

  .weekday-wrapper {
    grid-template-columns: 1fr;
  }

  .radio-options {
    grid-template-columns: 1fr;
  }
}
</style>
