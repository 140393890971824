var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.periodicReservationList,"fixed-header":"","hide-default-footer":"","mobile-breakpoint":"0","items-per-page":_vm.periodicReservationList.length},scopedSlots:_vm._u([{key:"item.day",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-sm font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(_vm.getDay(item.day) || "n/c")+" ")])]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"text-sm text-secondary font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(_vm.getPeriod(item.period))+" ")])])]}},{key:"item.repetitionperiod",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"text-sm text-secondary font-weight-bold mt-4 mb-0"},[_vm._v(" "+_vm._s(_vm.getRepetionPeriod(item.repetitionperiod))+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mt-4 p-0 text-blue",staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){return _vm.deleteReservation(item)}}},[_vm._v(" Supprimer ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }