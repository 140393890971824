<template>
  <v-card class="card-shadow border-radius-xl px-4 py-4">
    <div class="resa-head mb-8">
      <div>
        <h4>Invitations</h4>
      </div>
      <div>
        <InvitationForm @updateInvitations="fetchInvitations"></InvitationForm>
      </div>
    </div>
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Invité
              </th>
              <th
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                  uppercase
                "
              >
                Date
              </th>
              <th
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Status
              </th>
              <th
                class="
                  text-center text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in invitations" :key="item.id">
              <td :class="{ 'border-bottom': i != invitations.length - 1 }">
                <v-list class="py-0">
                  <v-list-item class="px-0 py-1">
                    <!-- <v-list-item-avatar rounded :size="36" class="my-0 me-5">
                      <v-img
                        :alt="item.avatar"
                        :src="item.avatar"
                        class="border-radius-lg"
                      ></v-img>
                    </v-list-item-avatar> -->

                    <v-list-item-content>
                      <v-list-item-title
                        class="mb-0 text-sm text-typo font-weight-bold"
                        >{{ item.name }} {{ item.surname }}</v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        item.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </td>
              <td
                class="text-sm text-secondary mb-0"
                :class="{ 'border-bottom': i != invitations.length - 1 }"
              >
                {{ item.initdate.split("-").reverse().join("/") }}
              </td>
              <td
                class="text-h5"
                :class="{ 'border-bottom': i != invitations.length - 1 }"
              >
                <v-chip
                  :color="'bg-gradient-success'"
                  class="ml-0 mr-2 black--text"
                  label
                  small
                >
                  {{ item.status }}
                </v-chip>
              </td>
              <td>
                <v-menu
                  transition="slide-y-transition"
                  offset-y
                  offset-x
                  min-width="300"
                  max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      color="#fff"
                      class="font-weight-bolder btn-outline-default py-4 px-2"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      Autres actions
                    </v-btn>
                  </template>

                  <v-list class="py-0d">
                    <v-list-item
                      class="pa-4 list-item-hover-active"
                      @click="deleteInvitation(item)"
                    >
                      <v-list-item-title class="p-6"
                        >Supprimer
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.status.toLowerCase() === 'pending'"
                      class="pa-4 list-item-hover-active"
                      @click="sendMail(item)"
                    >
                      <v-list-item-title class="p-6"
                        >Renvoyer l'email
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import InvitationForm from "./InvitationForm.vue";

export default {
  name: "light-table-transparent",

  components: {
    InvitationForm,
  },

  data() {
    return {
      invitations: [],
    };
  },

  beforeMount() {
    this.fetchInvitations();
  },

  methods: {
    fetchInvitations() {
      axios
        .get(`${this.$store.getters.apiurl}/invit`)
        .then((res) => {
          this.invitations = res.data;
        })
        .catch((err) => {
          console.error("Error on fetch invitations", err);
        });
    },

    deleteInvitation(invit) {
      console.log("DELETE INVITATION: ", invit);
      axios
        .delete(
          `${this.$store.getters.apiurl.replace("/v2", "")}/invit/${invit.id}`
        )
        .then((res) => {
          console.log("DELETE RESPONSE: ", res);
          this.fetchInvitations();
        })
        .catch((err) => {
          console.error("Error on delete invitation: ", err);
        });
    },

    sendMail(invit) {
      console.log(invit);
      let subject = "Votre invitation";
      const inviteURL = `${this.$store.getters.apiurl.replace(
        "/v2",
        ""
      )}/invitations/${invit.id}`;
      console.log("inviteURL: ", inviteURL);
      document.location = `mailto:${
        invit.email
      }?subject=${subject}&body=${encodeURIComponent(
        this.emailbody(invit, inviteURL)
      )}`;
    },

    emailbody(invit, inviteURL) {
      return `Cher ${invit.surname.toUpperCase()} ${invit.name}:
        \n\rLa société ${this.$store.getters.enterprise.toUpperCase()}
         vous invite à réserver votre place pour votre rendez-vous du ${invit.initdate
           .split("-")
           .reverse()
           .join("/")}.
        \n\rMerci de renseigner les éléments demandés dans le lien suivant: ${inviteURL}
        \n\rÀ votre arrivée sur site, la reconnaissance de votre plaque d'immatriculation permettra l'ouverture de la porte (délai d’ouverture de 4 secondes à prendre en compte).
        \n\rBonne journée.`;
    },
  },
};
</script>

<style scoped>
.resa-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
