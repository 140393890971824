<template>
  <div>
    <div v-if="pendingRequest" class="grid-center-h-v">
      <v-progress-circular
        :size="32"
        :width="2"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-btn
        v-if="message == ''"
        :disabled="disableForceOpen"
        :elevation="0"
        color="#cb0c9f"
        class="
          font-weight-bolder
          btn-danger
          bg-gradient-info
          px-2
          py-6
          mt-auto
          w-100
        "
        :class="disableForceOpen && 'disabled-force-open'"
        @click="openGate"
      >
        {{ !disableForceOpen ? "OUVRIR LA BARRIÈRE" : "PAS DE RESERVATION" }}
      </v-btn>
      <p class="text-md text-center mb-0 text-body" @click="message = ''">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BtnUserForceOpen",

  props: {
    user: {
      type: Object,
      required: true,
    },
    reservations: {
      type: [null, Array],
      required: true,
    },
  },

  data() {
    return {
      pendingRequest: false,
      message: "",
    };
  },
  computed: {
    disableForceOpen: function () {
      return !(
        this.user.permissions.permissions.find((elm) => elm.name == "access") ||
        this.getReservationToday()
      );
    },
  },

  methods: {
    /**
     * OPEN GATE
     * Open gate for a user with a specific vehicle
     */
    openGate() {
      this.message = null;
      this.pendingRequest = true;
      const platenumber = this.user?.plates?.cars[0];
      const parking = this.$store.getters.parkings.find(
        (parking) => parking.id === this.user.mainparking
      );
      const urlparams =
        platenumber && parking && parking.barriers[0].barrierid
          ? `${this.user.mainparking}?userid=${this.user.id}&platenumber=${platenumber}&barrierid=${parking.barriers[0].barrierid}&force=true`
          : `${this.user.mainparking}?barrierid=${parking.barriers[0].barrierid}&force=true`;
      axios
        .get(`${this.$store.getters.apiurl}/open/${urlparams}`)
        .then(() => {
          this.messageType = "green";
          this.message = "Ouverture réalisée!";
          this.pendingRequest = false;
        })
        .catch((err) => {
          this.messageType = "red";
          this.message = "L'ouverture a échouée";
          this.pendingRequest = false;
          console.error("Open gate ERROR: ", err);
        });
    },
    getReservationToday() {
      const today = new Date();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const todayDateFormat = `${today.getFullYear()}-${month}-${day}`;
      const matchDate = this.reservations.find(
        (resa) => resa.initdate == todayDateFormat
      );
      return matchDate && todayDateFormat == matchDate.initdate;
    },
  },
};
</script>

<style scoped>
.disabled-force-open {
  opacity: 0.5;
  filter: grayscale(1);
}
</style>
