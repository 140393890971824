<template>
  <v-card class="card-shadow border-radius-xl px-4 py-4">
    <h4 class="mb-4">Compteurs</h4>
    <!-- RESERVATIONS -->
    <p class="text-sm text-body" v-if="userLimits.reservations">
      Vous avez effectué {{ userLimits.reservations.count }}/{{
        userLimits.reservations.maxUsage
      }}
      réservations dans les
      {{ userLimits.reservations.maxUsagePeriod }} prochains jours
    </p>
    <!-- INVITATIONS -->
    <p v-if="userLimits.invitations" class="text-sm text-body">
      Vous avez effectué {{ userLimits.invitations.count }}/{{
        userLimits.invitations.maxUsage
      }}
      invitations dans les {{ userLimits.invitations.maxUsagePeriod }} prochains
      jours
    </p>
    <!-- ACCESS -->
    <p v-if="userLimits.access" class="text-sm text-body">
      Vous avez effectué {{ userLimits.access.count }}/{{
        userLimits.access.maxUsage
      }}
      accès dans les {{ userLimits.access.maxUsagePeriod }} derniers jours.
    </p>
  </v-card>
</template>

<script>
export default {
  name: "UserLimits",

  props: {
    userLimits: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped></style>
