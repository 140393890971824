<template>
  <div class="container">
    <v-row style="padding: 12px">
      <img
        :src="getCompanyLogo"
        alt="logo entreprise"
        class="company-logo"
        :style="{ background: getMainColor }"
      />
    </v-row>
    <v-row v-if="user">
      <v-col :md="hasVehicle ? 8 : 12" sm="12">
        <v-row>
          <v-col md="8" sm="12">
            <!-- Info Card Component START -->
            <v-card
              class="card-shadow border-radius-xl px-4 py-4"
              v-if="canReserve"
            >
              <v-row no-gutters>
                <v-col>
                  <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                    Prochaine Réservation
                  </h5>
                  <p
                    class="text-md mb-0 text-body"
                    v-html="getUserNextReservation"
                  ></p>
                </v-col>
              </v-row>
            </v-card>
            <!-- Info Card Component END -->
          </v-col>
          <v-col cols="4" class="mobile-view">
            <!-- Mini Card Component START -->
            <v-card
              v-if="hasVehicle"
              class="card-shadow border-radius-xl px-4 py-4 h-100"
            >
              <v-row no-gutters>
                <v-col sm="8">
                  <p class="text-sm mb-0 font-weight-bold text-body">
                    Véhicule par défaut
                  </p>
                  <h5 class="text-h4 text-typo font-weight-bolder mb-0">
                    {{ getDefaultVehicle.immat }}
                  </h5>
                </v-col>
                <v-col sm="4" class="text-end">
                  <v-avatar color="bg-gradient-info" class="shadow" rounded>
                    <img
                      :src="require(`@/assets/icons/${defautVehiculeIcon}.svg`)"
                      style="padding: 0.5rem"
                      alt="icon"
                    />
                  </v-avatar>
                </v-col>
              </v-row>
            </v-card>
            <!-- Mini Card Component END -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="only-mobile-view">
            <BtnUserForceOpen
              :user="user"
              :reservations="userReservations"
            ></BtnUserForceOpen>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" v-if="canReserve">
            <MesReservations
              :user="user"
              @updateReservations="fetchUserReservations"
            ></MesReservations>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="mobile-view" v-if="user && hasVehicle">
        <MesVehicules :vehicles="vehicles" :user="user"></MesVehicules>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" v-if="canInvite" class="mobile-view">
        <Invitation></Invitation>
      </v-col>
    </v-row>
    <v-row v-if="userLimits && Object.keys(userLimits).length > 0">
      <v-col cols="8" class="mobile-view">
        <UserLimits :userLimits="userLimits"></UserLimits>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import MesVehicules from "../../components/Dynapark/MesVehicules.vue";
import MesReservations from "../../components/Dynapark/MesReservations.vue";
import Invitation from "../../components/Dynapark/Invitation.vue";
import BtnUserForceOpen from "../../components/Dynapark/BtnUserForceOpen.vue";
import UserLimits from "../../components/Dynapark/UserLimits.vue";

export default {
  name: "DashBoard",
  components: {
    MesVehicules,
    MesReservations,
    Invitation,
    BtnUserForceOpen,
    UserLimits,
  },

  data() {
    return {
      defautVehiculeIcon: "car_white",
      user: null,
      vehicles: [],
      userReservations: [],
      userInvitations: [],
      userLimits: null,
    };
  },

  beforeMount() {
    const userId = this.$store.getters.userId;
    axios
      .get(`${this.$store.getters.apiurl}/users/${userId}`)
      .then((res) => {
        this.user = res.data;
        this.vehicles = res.data.plates;
      })
      .catch((err) => {
        console.error("Get user ERROR: ", err);
      });

    this.fetchUserReservations();
    this.fetchUserLimits();
  },

  computed: {
    getCompanyLogo() {
      return this.$store.getters.companyLogo;
    },

    getMainColor() {
      return this.$store.getters.mainColor;
    },

    getDefaultVehicle() {
      if (this.vehicles.cars && this.vehicles.cars.length > 0) {
        return { immat: this.vehicles.cars[0], icon: "car_white.png" };
      } else if (
        this.vehicles.motorbike &&
        this.vehicles.motorbike.length > 0
      ) {
        return { immat: this.vehicles.motorbike[0], icon: "car_white" };
      }
      return { immat: "", icon: "car_white" };
    },

    hasVehicle() {
      return (
        (this.vehicles.cars && this.vehicles.cars.length > 0) ||
        (this.vehicles.motorbike && this.vehicles.motorbike.length > 0)
      );
    },

    canReserve() {
      return (
        this.user &&
        this.user.permissions &&
        this.user.permissions.permissions &&
        this.user.permissions.permissions.find(
          (elm) => elm.name === "reservation"
        ) !== undefined
      );
    },

    canInvite() {
      return (
        this.user &&
        this.user.permissions &&
        this.user.permissions.permissions &&
        this.user.permissions.permissions.find(
          (elm) => elm.name === "invitation"
        ) !== undefined
      );
    },

    getUserNextReservation() {
      if (this.userReservations.length === 0) {
        return "Aucune";
      }
      const months = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septempre",
        "octobre",
        "novembre",
        "decembre",
      ];
      const resDate = new Date(this.userReservations[0].initdate);
      const day = resDate.getDate();
      const month = months[resDate.getMonth()];
      const period =
        this.userReservations[0].initperiod === "am" ? "matin" : "après-midi";
      const place = this.userReservations[0].spotnumber;
      return `le ${day} ${month} <span class="text-indigo">${period}</span> à la place <span class="text-indigo">${place}</span>`;
    },
  },

  methods: {
    fetchUserReservations() {
      axios
        .get(`${this.$store.getters.apiurl}/reservations`)
        .then((res) => {
          this.userReservations = res.data;
        })
        .catch((err) => {
          console.error("Error on fetch user reservations", err);
        });
    },

    fetchUserLimits() {
      axios
        .get(`${this.$store.getters.apiurl}/userlimits`)
        .then((res) => {
          this.userLimits = res.data;
        })
        .catch((err) => {
          console.log("ERROR on fetch User Limits: ", err);
        });
    },
  },
};
</script>

<style scoped>
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.company-logo {
  width: 100%;
  object-fit: contain;
  height: 30vh;
  max-height: 200px;
  border-radius: 2rem;
}
</style>
